import {find, flatten} from 'lodash-es';

export function usePageExtras() {

    function getAllUniqueMenuItems() {
        const {data} = useNuxtData('menus');

        const menus: any = data.value.data;

        const menuItems = flatten(menus.map(menu => menu.menu_items));
        const childMenuItems = flatten(menuItems.map(item => item.children ?? []));

        const allMenuItems = [];
        const uniqueKeys = new Set();
        [
            ...menuItems,
            ...childMenuItems,
        ].forEach(function (menuItem) {
            const key = `${menuItem.menuable_type}-${menuItem.menuable_id}`;

            if (!uniqueKeys.has(key)) {
                uniqueKeys.add(key);
                allMenuItems.push(menuItem);
            }
        });

        return allMenuItems;
    }

    function getMenuItemFromRoute(): any {

        const route = useRoute();

        const allMenuItems = getAllUniqueMenuItems();

        return find(allMenuItems, (menuItem: any) => {
            return menuItem.menuable?.url === route.path;
        });
    }

    function getCategoryTitle(): string {
        let localizedName = '';
        const matchedItem = getMenuItemFromRoute();

        if (matchedItem) {
            localizedName = matchedItem.localized_name;
        }

        return localizedName;
    }

    function getCategoryTitleForSlug(url: string) {
        let localizedName = '';
        const allMenuItems = getAllUniqueMenuItems();

        const matchedItem = find(allMenuItems, (menuItem: any) => {

            return menuItem.menuable?.url === url;
        });

        if (matchedItem) {
            localizedName = matchedItem.localized_name;
        }

        return localizedName;
    }

    return {
        getCategoryTitle,
        getMenuItemFromRoute,
        getCategoryTitleForSlug,
        getAllUniqueMenuItems,
    };
}